import type { RouteLocationNormalizedLoaded } from 'vue-router';

import type { AuthGuardAppConfig, AuthGuardMeta } from '~/types/authGuard';
import { AuthGuardMode } from '~/types/authGuard';

type NavigateToReturn = ReturnType<typeof navigateTo>;

type CheckAuthAndRedirectOptions = {
    preCheckSession?: boolean;
};

type CheckAuthAndRedirect = (
    routeArg?: RouteLocationNormalizedLoaded,
    options?: CheckAuthAndRedirectOptions
) => Promise<NavigateToReturn | undefined>;

export interface UseAuthGuardReturnType {
    checkAuthAndRedirect: CheckAuthAndRedirect;
}

export const useAuthGuard = (): UseAuthGuardReturnType => {
    const authGuardConfig = useAppConfig().authGuard as AuthGuardAppConfig;
    const localePath = useLocalePath();
    const { isLoggedIn, getSession } = useAuthUtils();

    const checkAuthAndRedirect: CheckAuthAndRedirect = async (routeArg, { preCheckSession = false } = {}) => {
        const route = routeArg ?? useRoute();

        const hasAuthGuard = Array.isArray(route.meta.middleware)
            ? route.meta.middleware.some(middleware => middleware === 'auth-guard')
            : route.meta.middleware === 'auth-guard';

        if (!hasAuthGuard) return;

        const authGuardMeta = route.meta.authGuard as AuthGuardMeta;

        if (preCheckSession && authGuardMeta.mode === AuthGuardMode.AUTHENTICATED_ONLY && isLoggedIn.value) {
            await getSession();
        }

        if (
            (authGuardMeta.mode === AuthGuardMode.GUEST_ONLY && isLoggedIn.value) ||
            (authGuardMeta.mode === AuthGuardMode.AUTHENTICATED_ONLY && !isLoggedIn.value)
        ) {
            const redirect = localePath(authGuardMeta?.redirect ?? authGuardConfig.redirect[authGuardMeta.mode]);

            return navigateTo(redirect);
        }
    };

    return { checkAuthAndRedirect };
};
